import { useEffect, useState } from "react";
import kadvice from "kadvice";

function Main() {
  useEffect(() => setMotivator(kadvice.random()), []);

  const [motivator, setMotivator] = useState({});

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <img
        src="https://rare-gallery.com/uploads/posts/709846-Salar-de-Uyuni-Bolivia-Sunrises-and-sunsets.jpg"
        alt=""
        style={{
          position: "absolute",
          objectFit: "cover",
          objectPosition: "bottom",
          width: "100%",
          height: "100%",
        }}
      />

      <div
        style={{
          padding: "5%",
          position: "relative",
          fontFamily: "OTEnjoystoriesBA",
        }}
      >
        <h1>
          {motivator.author} ({motivator.authorProfile})
        </h1>
        <h2>{motivator.message}</h2>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "0%",
          textAlign: "center",
          width: "100%",
          fontFamily: "Helvetica",
        }}
      >
        <h5>Copyright 2023. Gym Lee. All rights reserved.</h5>
        <h6>
          <a
            href="https://github.com/JiminLeeDev"
            style={{
              cursor: "pointer",
              color: "black",
            }}
          >
            GitHub
          </a>
        </h6>
      </div>
    </div>
  );
}

export default Main;
